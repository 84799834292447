@import "../config";

// Normal styles
@font-face {
  font-family: "Noto Sans";
  font-weight: 500;
  src:
    local("Noto Sans Medium"),
    local("Noto Sans Med"),
    local("NotoSans-Medium"),
    url("#{$mri-noto-sans-default-path}NotoSans-Medium.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Medium";
  src:
    local("Noto Sans Medium"),
    local("Noto Sans Med"),
    local("NotoSans-Medium"),
    url("#{$mri-noto-sans-default-path}NotoSans-Medium.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Medium.ttf") format("truetype");
}

// Italic
@font-face {
  font-family: "Noto Sans";
  font-weight: 500;
  font-style: italic;
  src:
    local("Noto Sans Medium"),
    local("Noto Sans Med"),
    local("NotoSans-MediumItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-MediumItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-MediumItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Medium";
  font-style: italic;
  src:
    local("Noto Sans Medium"),
    local("Noto Sans Med"),
    local("NotoSans-MediumItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-MediumItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-MediumItalic.ttf") format("truetype");
}