@import "../config";

// Normal style
@font-face {
  font-family: "Noto Sans";
  font-weight: 200;
  src:
    local("Noto Sans ExtraLight"),
    local("Noto Sans ExtLt"),
    local("NotoSans-ExtraLight"),
    url("#{$mri-noto-sans-default-path}NotoSans-ExtraLight.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans ExtraLight";
  src:
    local("Noto Sans ExtraLight"),
    local("Noto Sans ExtLt"),
    local("NotoSans-ExtraLight"),
    url("#{$mri-noto-sans-default-path}NotoSans-ExtraLight.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ExtraLight.ttf") format("truetype");
}

// Italic
@font-face {
  font-family: "Noto Sans";
  font-weight: 200;
  font-style: italic;
  src:
    local("Noto Sans ExtraLight"),
    local("Noto Sans ExtLt"),
    local("NotoSans-ExtraLightItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-ExtraLightItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans ExtraLight";
  font-style: italic;
  src:
    local("Noto Sans ExtraLight"),
    local("Noto Sans ExtLt"),
    local("NotoSans-ExtraLightItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-ExtraLightItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ExtraLightItalic.ttf") format("truetype");
}