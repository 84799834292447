@import "../config";

// Normal style
@font-face {
  font-family: "Noto Sans";
  font-weight: 100;
  src:
    local("Noto Sans Thin"),
    local("NotoSans-Thin"),
    url("#{$mri-noto-sans-default-path}NotoSans-Thin.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Thin";
  src:
    local("Noto Sans Thin"),
    local("NotoSans-Thin"),
    url("#{$mri-noto-sans-default-path}NotoSans-Thin.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Thin.ttf") format("truetype");
}

// Italic
@font-face {
  font-family: "Noto Sans";
  font-weight: 100;
  font-style: italic;
  src:
    local("Noto Sans Thin"),
    local("NotoSans-ThinItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-ThinItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ThinItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Thin";
  font-style: italic;
  src:
    local("Noto Sans Thin"),
    local("NotoSans-ThinItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-ThinItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ThinItalic.ttf") format("truetype");
}