@import "../config";

// Normal style
@font-face {
  font-family: "Noto Sans";
  font-weight: 600;
  src:
    local("Noto Sans SemiBold"),
    local("Noto Sans SemBd"),
    local("NotoSans-SemiBold"),
    url("#{$mri-noto-sans-default-path}NotoSans-SemiBold.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans SemiBold";
  src:
    local("Noto Sans SemiBold"),
    local("Noto Sans SemBd"),
    local("NotoSans-SemiBold"),
    url("#{$mri-noto-sans-default-path}NotoSans-SemiBold.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-SemiBold.ttf") format("truetype");
}

// Italic
@font-face {
  font-family: "Noto Sans";
  font-weight: 600;
  font-style: italic;
  src:
    local("Noto Sans SemiBold"),
    local("Noto Sans SemBd"),
    local("NotoSans-SemiBoldItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-SemiBoldItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans SemiBold";
  font-style: italic;
  src:
    local("Noto Sans SemiBold"),
    local("Noto Sans SemBd"),
    local("NotoSans-SemiBoldItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-SemiBoldItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-SemiBoldItalic.ttf") format("truetype");
}