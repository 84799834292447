@import "../config";

// Normal style
@font-face {
  font-family: "Noto Sans";
  font-weight: 700;
  src:
    local("Noto Sans Bold"),
    local("Noto Sans"),
    local("NotoSans-Bold"),
    url("#{$mri-noto-sans-default-path}NotoSans-Bold.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Bold";
  src:
    local("Noto Sans Bold"),
    local("Noto Sans"),
    local("NotoSans-Bold"),
    url("#{$mri-noto-sans-default-path}NotoSans-Bold.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Bold.ttf") format("truetype");
}


// Italic
@font-face {
  font-family: "Noto Sans";
  font-weight: 700;
  font-style: italic;
  src:
    local("Noto Sans Bold"),
    local("Noto Sans"),
    local("NotoSans-BoldItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-BoldItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-BoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Bold";
  font-style: italic;
  src:
    local("Noto Sans Bold"),
    local("Noto Sans"),
    local("NotoSans-BoldItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-BoldItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-BoldItalic.ttf") format("truetype");
}