@import "../config";

// Normal style
@font-face {
  font-family: "Noto Sans";
  font-weight: 800;
  src:
    local("Noto Sans ExtraBold"),
    local("Noto Sans ExtBd"),
    local("NotoSans-ExtraBold"),
    url("#{$mri-noto-sans-default-path}NotoSans-ExtraBold.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans ExtraBold";
  src:
    local("Noto Sans ExtraBold"),
    local("Noto Sans ExtBd"),
    local("NotoSans-ExtraBold"),
    url("#{$mri-noto-sans-default-path}NotoSans-ExtraBold.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ExtraBold.ttf") format("truetype");
}

// Italic
@font-face {
  font-family: "Noto Sans";
  font-weight: 800;
  font-style: italic;
  src:
    local("Noto Sans ExtraBold"),
    local("Noto Sans ExtBd"),
    local("NotoSans-ExtraBoldItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-ExtraBoldItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans ExtraBold";
  font-style: italic;
  src:
    local("Noto Sans ExtraBold"),
    local("Noto Sans ExtBd"),
    local("NotoSans-ExtraBoldItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-ExtraBoldItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-ExtraBoldItalic.ttf") format("truetype");
}