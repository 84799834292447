@import "../config";

// Normal style
@font-face {
  font-family: "Noto Sans";
  src:
    local("Noto Sans"),
    local("NotoSans-Regular"),
    url("#{$mri-noto-sans-default-path}NotoSans-Regular.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Regular";
  src:
    local("Noto Sans"),
    local("NotoSans-Regular"),
    url("#{$mri-noto-sans-default-path}NotoSans-Regular.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Regular.ttf") format("truetype");
}

// Italic
@font-face {
  font-family: "Noto Sans";
  font-style: italic;
  src:
    local("Noto Sans"),
    local("NotoSans-RegularItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-Italic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Regular";
  font-style: italic;
  src:
    local("Noto Sans"),
    local("NotoSans-RegularItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-Italic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Italic.ttf") format("truetype");
}