@import "../config";

// Normal style
@font-face {
  font-family: "Noto Sans";
  font-weight: 900;
  src:
    local("Noto Sans Black"),
    local("Noto Sans Blk"),
    local("NotoSans-Black"),
    url("#{$mri-noto-sans-default-path}NotoSans-Black.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Black";
  src:
    local("Noto Sans Black"),
    local("Noto Sans Blk"),
    local("NotoSans-Black"),
    url("#{$mri-noto-sans-default-path}NotoSans-Black.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-Black.ttf") format("truetype");
}

// Italic
@font-face {
  font-family: "Noto Sans";
  font-weight: 900;
  font-style: italic;
  src:
    local("Noto Sans Black"),
    local("Noto Sans Blk"),
    local("NotoSans-BlackItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-BlackItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "Noto Sans Black";
  font-style: italic;
  src:
    local("Noto Sans Black"),
    local("Noto Sans Blk"),
    local("NotoSans-BlackItalic"),
    url("#{$mri-noto-sans-default-path}NotoSans-BlackItalic.ttf") format("truetype"),
    url("#{$mri-noto-sans-cdn-path}NotoSans-BlackItalic.ttf") format("truetype");
}